@import 'assets/variables';

.ParagraphHelpPages {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  &__inner__content__body {
    margin-bottom: 4rem;
  }
}
