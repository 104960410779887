@import 'assets/variables';
@import 'assets/mixins';
@import 'assets/icons';

.ParagraphWebform {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$tablet} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }
}

.WebformComponent__form {
  input {
    font-size: 1.6rem;
    caret-color: $color-blue-primary;
  }

  fieldset legend {
    @include h3;
  }

  label {
    font-weight: 700;
  }

  // Set error styling webforms.
  .FormElement__error,
  .WebformFileUpload__error {
    margin-top: 0.5rem;
    font-size: $font-size--caption;
    font-style: italic;
    line-height: $line-height--caption;
    color: $color-system-error;
    letter-spacing: normal;
  }

  .FormElement__inner {
    textarea {
      padding: 1.3rem 2rem;
      border: $color-blue-primary solid 0.1rem;
      border-radius: 0.5rem;
    }
  }

  .WebformEmail {
    &:has(.WebformEmail--Confirm__error) {
      .WebformEmail--Confirm__inner {
        input {
          border: $color-system-error solid 0.1rem;
        }
      }
    }

    &--Confirm {
      &__inner {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      label {
        font-size: 1.6rem;
      }
    }
  }

  .WebformSelect {
    .Select {
      &__inner {
        &__value-container {
          padding: .5rem 1.5rem !important;
        }

        &__menu {
          border-bottom-right-radius: 2.5rem !important;
          border-bottom-left-radius: 2.5rem !important;

          &-list {
            padding: 0 !important;
            border-right: $color-blue-dark .1rem solid !important;
            border-bottom: $color-blue-dark .1rem solid !important;
            border-left: $color-blue-dark .1rem solid !important;
            border-bottom-right-radius: 2.5rem !important;
            border-bottom-left-radius: 2.5rem !important;
          }
        }

        &__placeholder {
          font-size: $font-size--body--small;
        }

        &__option {
          padding: 1.3rem 2.1rem !important;
          font-size: $font-size--body--small;
          color: $color-blue-darkest !important;
          border-top: $color-blue-primary .1rem solid;

          &:hover {
            background-color: $color-blue-light;

            &:last-child {
              border-bottom-right-radius: 2.5rem !important;
              border-bottom-left-radius: 2.5rem !important;
            }
          }
        }

        &__indicator-separator,
        &__clear-indicator {
          display: none !important;
        }

        &__control {
          border: .1rem solid $color-blue-primary !important;
          border-radius: 2.5rem !important;

          &--menu-is-open {
            border-color: $color-blue-dark !important;
            border-bottom: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;

            .Select__inner__dropdown-indicator {
              svg {
                transform: rotate(180deg) !important;
                color: $color-blue-darkest;
              }
            }

          }
        }

        &__dropdown-indicator {
          svg {
            color: $color-blue-darkest;
          }
        }

      }

      label {
        font-size: $font-size--body--small;
        line-height: 2rem;
        color: $color-blue-darkest;
      }
    }
  }

  .WebformCheckboxWrapper {
    .Checkbox {
      min-height: 5rem;

      &__inner {
        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: 0;
          font-family: $font--primary;
          font-size: $font-size--body--small;
          font-weight: 400;
          color: $color-blue-darkest;

          input {
            cursor: pointer;
            position: relative;
            width: 1.92rem;
            height: 1.92rem;
            margin-right: 1.74rem;
            appearance: none;
            background-color: $color-blue-light;
            border: .1rem solid $color-blue-primary;
            border-radius: .4rem;
            outline: none;
            transition-duration: 0.3s;
          }

          input:checked {
            background-color: $color-green-primary;
            border: .1rem solid $color-green-primary;

            &:after {
              opacity: 1;
            }
          }

          input:after {
            content: '';
            position: absolute;
            top: .30rem;
            right: .075rem;
            width: 1.4rem;
            height: 1.28rem;
            opacity: 0;
            background: url("../../../assets/images/icons/checkmark.svg");
            background-repeat: no-repeat;
          }
        }

        label[for="filter_tags--true"] {
          background-color: $color-green-light !important;
          border: .1rem solid $color-green-primary !important;
        }
      }
    }
  }

  .WebformRadios {
    .FormElement {
      label {
        flex-flow: row wrap;
        font-size: $font-size--body--small;
      }

      textarea {
        width: 100%;
      }

      span {
        font-weight: 600;
      }
    }
  }

  .WebformName {
    @media #{$mobile-only} {
      flex-wrap: wrap;

      .TextField:has(.first) {
        flex: 1 0 auto;
      }

      .TextField:has(.middle) {
        width: 33%;
      }

      .TextField:has(.last) {
        width: 100%;
      }
    }

    @media #{$tablet} {
      .TextField:has(.middle) {
        width: 30%;
      }
    }
  }

  .WebformWizardPage__actions {
    .WebformComponent__submit {
      display: flex;
      gap: 2rem;

      @media #{$mobile-only} {
        justify-content: space-between;
      }
    }
  }

  .WebformStepCounter,
  .WebformWizardPage__title {
    // hide for now
    display: none;
  }

  .WebformFileUpload {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    // Change the order of WebformFileUpload elements.
    &__link {
      order: 1;
      margin-bottom: 0;
      font-weight: normal;
    }

    &__error {
      order: 2;
    }

    // Hide unnecessary elements of Webform file upload.
    &__description, &__icon, &__uploaded-files:empty, > input {
      display: none;
      visibility: hidden;
    }

    &__maxfilesize {
      margin-top: 1rem;
      font-size: $font-size--caption;
      line-height: $line-height--caption;
    }

    &__uploaded-files {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      order: 3;
      margin-top: 1rem;
    }

    &__uploaded-file {
      display: flex;
      align-items: center;
      height: 5rem;
      border: $color-blue-primary solid 0.1rem;
      border-radius: 2.5rem;

      &:has(.WebformFileUpload__uploaded-file__remove:hover) {
        background-color: $color-blue-light;
      }

      &__wrapper {
        width: 100%;
        padding: 0 2rem;
      }

      &__text-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
      }

      &__title {
        flex: auto;
        font-size: $font-size--body--small;
        line-height: $line-height--body--small;
      }

      &__remove {
        @include icon('cross');

        flex: 0 1 auto;
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;

        &:hover {
          @include icon-variant(7);

          cursor: pointer;
        }
      }
    }

    .row {
      order: 4;
    }

    .Button {
      @include button-primary-variant;

      border: 0;
    }
  }

  .WebformDutchAddressAutocomplete {
    // Styling of the Form elements within the DutchAdressAutocomplete.
    .AddressAutocomplete__inner {
      display: grid;
      grid-gap: 3rem 1rem;
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;

      .FormElement {
        margin-bottom: 0;
      }

      // Full row width for the 3rd+ items.
      .FormElement:nth-child(n+3) {
        grid-column: 1 / -1;
      }
    }
  }
}
