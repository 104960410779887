@import 'assets/variables';
@import 'assets/mixins';

.ParagraphDownloads {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  &__body {
    margin-bottom: 3rem;
  }
}
