@import 'assets/mixins';
@import 'assets/grid';
@import 'assets/variables';

.App {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &.client-side {
    .page--inner-wrapper {
      transform: none;
    }
  }

  .cookie_compliance_popup {
    pointer-events: none;
    z-index: 200;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    @media screen and (min-width: $screen-md) {
      padding: 2rem 3rem;

      &__inner_wrapper {
        flex-direction: row;
        line-height: 1.56;
      }

      &__content_container {
        padding: 2rem 3rem;
        border-bottom: 0;
      }

      &__button {
        width: 100%;
        height: 50%;
        padding: 1.5rem 3rem;

        &--agree {
          border-width: 0 0 .1rem 0;
        }
      }

      &__buttons-container {
        cursor: pointer;
        flex-direction: column;
        justify-content: space-evenly;
        width: auto;
      }
    }

    &__hidden {
      display: none;
      animation: none;
      animation-fill-mode: none;
    }

    &__inner_wrapper {
      pointer-events: all;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 77rem;
      background-color: $color-blue-darkest;
      backdrop-filter: blur(.5rem);
      border-radius: 1rem;
      box-shadow: 0 .2rem .4rem 0 rgb(0 0 0 / 10%);
    }

    &__content_container {
      width: 100%;
      padding: 1rem 2rem;
      color: $color-white;
      border-color: #00505B;

      p,a {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }

      a {
        margin-left: .5rem;
        color: #00BED8;
        text-decoration: underline;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      padding: 1.5rem;
      font-family: $font--secondary;
      font-size: $font-size--body--small;
      font-weight: 400;
      color: #00BED8;
      text-align: center;

      &--agree {
        border-color: #00505B;
        border-width: 0 .1rem 0 0;
      }
    }

    &__buttons-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      font-weight: bold;
      color: $color-white;
      border-color: #00505B;
      transition: color 0.15s ease-in-out;
    }
  }
}
