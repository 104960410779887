@import 'assets/variables';
@import 'assets/mixins';

.NodeVacancyDetail {
  &__title {
    h1 {
      margin-bottom: 2rem;

      @media #{$normal} {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__body {
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 3rem;
    }

    a {
      color: $color-blue-primary;
    }
  }

  &__created_date {

    margin-bottom: 6rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: $color-blue-dark;

    @media #{$tablet} {
      margin-bottom: 8rem;
    }

    @media #{$normal} {
      margin-bottom: 12rem;
    }

  }
}
