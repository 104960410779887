@import 'assets/variables';
@import 'assets/mixins';

.WebformSearchField {
  padding-bottom: 3rem;

  .SearchField {
    position: relative;
    width: 26rem;
    height: 5rem;
    margin-bottom: 3rem;

    &__error {
      font-style: normal;
    }

    i {
      position: absolute;
      top: 3.5rem;
      left: 5%;
    }

    span {
      position: absolute;
      top: 3.8rem;
      left: 19rem;
      display: none;
      font-family: $font--quaternary;
      font-size: 1.8rem;
      font-weight: 600;
      color: $color-blue-primary;
    }

    label {
      font-size: $font-size--body--small;
      line-height: 2rem;
      color: $color-blue-darkest;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      appearance:none;
    }

    input:not(:placeholder-shown) ~ span,
    input:focus ~ span {
      display: block;
    }

    input[type="search"] {
      width: inherit;
      height: inherit;
      padding: 1.3rem 7.5rem 1.3rem 3.8rem !important;
      border: 1px solid $color-blue-primary;
      border-radius: 2.5rem;


      &:focus {
        border: .1rem solid $color-black !important;
        outline: none;
      }

      &:invalid {
        border: 1px solid $color-system-error;

        + span:after {
          content: "";
          position: absolute;
          top: 45%;
          width: 1.8rem;
          height: 1.8rem;
          margin-left: 26rem !important;
          background: url("../../assets/images/icons/close.svg") !important;
          background-repeat: no-repeat !important;
        }
      }

      &:valid:not(:placeholder-shown) + span:after {
        content: "";
        position: absolute;
        top: 45%;
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 26rem;
        background: url("../../assets/images/icons/valid.svg") !important;
        background-repeat: no-repeat !important;
      }

      &.error {
        border: 1px solid $color-system-error !important;
      }
    }
  }
}
