.ParagraphUSP {
  &__wrapper {
    margin-bottom: 8rem;
    text-align: center;
  }

  &__header {
    margin-bottom: 4rem;
  }

  &__item {
    margin-bottom: 4rem;
  }
}
