@import "assets/variables";
@import "assets/icons";

*,
*:before,
*:after {
  box-sizing: border-box;
}

.hidden {
  display: none;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

main.main {
  min-height: calc(100vh - #{$menu-height-mobile});
  margin-top: 12rem;

  @media #{$tablet} {
    min-height: calc(100vh - #{$menu-height-tablet});
    margin-top: 10rem;
  }

  @media #{$normal} {
    min-height: calc(100vh - #{$menu-height-desktop});
    margin-top: 12rem;
  }
}

body {
  overflow-y: scroll;
  margin: 0;
  padding: 0;

  &.overflow-hidden {
    overflow-y: hidden;
  }
}

.ParagraphIdentifier {
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: bold;
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
}

// .ParagraphIdentifierItem {
//   border-bottom: 1px solid grey;
// }

.ParagraphIdentifierFieldValue {
  margin-left: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-grey;
  border-left: 1px solid $color-grey;

  .name {
    display: inline-block;
    padding-right: 0.5rem;
    font-weight: bold;
  }

  .type {
    display: inline-block;
    margin-bottom: 1rem;
    text-decoration: underline;
  }
}

.ParagraphAnchorWrapper {
  // Offset the anchor position to the top of the screen
  margin-top: -4rem;
  padding-top: 4rem;
}

.ContextualActionsButtons {
  .Icon-filled-edit {
    @include icon;
  }
}
