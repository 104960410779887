@import "assets/variables";
@import "assets/mixins";

body .Header {
  display: flex;
  justify-content: center;
  height: $menu-height-mobile;
  box-shadow: $menu-border;
  transition: background-color 0.3s linear;

  @media #{$tablet} {
    height: $menu-height-tablet;
  }

  @media #{$normal} {
    height: $menu-height-desktop;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 0 1rem 0 2rem;

    @media #{$tablet} {
      padding: 0 3.4rem;
    }

    @media #{$normal} {
      padding: 0 6rem;
    }
  }

  &__menu {
    display: none;

    @media #{$wide} {
      display: block;
    }

    .Menu.menu--main {
      display: flex;
      align-items: center;

      .MainMenu__item {
        margin-right: 4rem;
        text-wrap: nowrap;

        &:last-of-type {
          margin-right: 0;
        }

        &__link {
          font-family: $font--secondary;
          font-size: 1.6rem;
          line-height: 2rem;
          color: $color-blue-darkest;
          text-decoration: none;

          &:hover {
            color: $color-blue-verpact;
          }
        }
      }
    }

    .MagicLine {
      position: relative;
      display: flex;

      &__magic_line {
        pointer-events: none;

        position: absolute;
        z-index: 0;
        top: -4.6rem;
        right: 100%;
        left: 0;
        transform: skewY(-0.5deg);
        width: auto;
        height: 0.5rem;
        padding: 0;
        opacity: 0;
        background-color: $color-blue-verpact;
        border-radius: 0 0 0.5rem 0.5rem;
        transition: opacity 0.3s 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);

        &--visible {
          opacity: 1;
          transition:
            left 0.3s cubic-bezier(0.64, 0.04, 0.35, 1),
            opacity 0.3s 0.3s cubic-bezier(0.64, 0.04, 0.35, 1),
            right 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
        }
      }
    }
  }

  &__links {
    z-index: $z-index-3;
    display: flex;
    align-items: center;

    &__search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 5rem;
      font-size: 3rem;

      @media #{$normal} {
        background-color: $color-white;
      }

      &:hover {
        background-color: $color-blue-light;
      }
    }

    >div {
      @media #{$tablet} {
        margin-right: 1rem;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .Menu.menu--highlighted {
      display: none;

      @media #{$tablet} {
        display: block;
      }
    }

    .Hamburger__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__logo {
    width: fit-content;

    &__text {
      width: 9.1rem;
      height: 5rem;
      background-image: url("../../assets/images/logo/afvalfonds-woordmerk.svg");
      background-repeat: no-repeat;
      background-size: contain;

      @media #{$tablet} {
        width: 10.9rem;
        height: 6rem;
      }

      @media #{$normal} {
        width: 12.7rem;
        height: 7rem;
      }
    }

    a {
      display: flex;
      align-items: center;
      width: fit-content;
    }
  }

  &__search_input {
    position: absolute;
    z-index: 1000;
    overflow: hidden;
    width: 100%;
    max-height: 0;
    background: $color-white;
    transition: max-height 0.3s ease-in-out;

    &__icon_search {
      position: absolute;
      top: 1.5rem;
      left: 1rem;
      font-size: 3rem;

      @media #{$normal} {
        top: 3.5rem;
        left: 6rem;
      }
    }

    &__icon_close {
      cursor: pointer;
      position: absolute;
      top: 1.5rem;
      right: 3.5rem;
      font-size: 3rem;

      @media #{$normal} {
        top: 3.5rem;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &.visible {
      max-height: 100%;
      transition: max-height 0.3s ease-in-out;
    }

    input {
      width: 80%;
      max-width: 100%;
      height: 6rem;
      margin: auto 5rem;
      padding: 1rem;
      background: 0 0;
      border: 0;
      caret-color: $color-blue-primary;

      @media #{$normal} {
        height: 10rem;
        margin: 0 13rem;
      }

      &:focus {
        outline: none;
      }
    }

    span {
      font-size: 3rem;
    }
  }

  &__hamburger__menu {
    pointer-events: none;
    position: fixed;
    z-index: $z-index-2;
    inset: 0 0 0 0;
    overflow-y: auto;

    opacity: 0;
    background-color: $color-blue-primary;
    transition: opacity 0.3s linear;

    &__container {
      margin-top: 8rem;

      @media #{$tablet} {
        overflow: hidden;
        margin-top: 15rem;
      }

      @media #{$normal} {
        margin-top: 18rem;
      }

      &__row {
        >div:first-child {
          @media #{$tablet} {
            display: none;
          }
        }
      }
    }

    &__main__menu {
      margin-bottom: 4rem;

      @media #{$tablet} {
        margin-bottom: 0;
      }

      .Menu.menu--main {
        .MainMenu__item {
          margin-bottom: 2rem;

          @media #{$normal} {
            margin-bottom: 4rem;
          }

          &__link {
            @include h2;

            color: $color-blue-darkest;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &--active {
            .MainMenu__item__link {
              text-decoration: underline;
              text-decoration-thickness: from-font;
              text-underline-offset: 0.5rem;
            }
          }
        }
      }
    }

    &__secondary__menu {
      @media #{$mobile-only} {
        padding-bottom: 12rem;
      }

      .Menu.menu--secondary {
        .SecondaryMenu__item {
          margin-bottom: 2rem;

          &__link {
            @include link--big;

            color: $color-blue-darkest;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          &--active {
            .SecondaryMenu__item__link {
              text-decoration: underline;
              text-decoration-thickness: from-font;
              text-underline-offset: 0.5rem;
            }
          }
        }
      }
    }

    &__quote {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      place-content: center;
      background-color: $color-blue-dark;

      &__text {
        @include intro;

        margin: 2rem 4rem !important;
        font-weight: 600;
        color: $color-white;
        text-align: center;
      }
    }

    &--open {
      pointer-events: all;
      opacity: 1;
    }

    .menu--highlighted {
      align-items: center;
      margin-bottom: 4rem;

      @media #{$tablet} {
        display: none;
      }

      a {
        width: 100%;
        background-color: $color-white;

        .Button_inner {
          flex: 1 1 auto;
          color: $color-blue-darkest;
        }
      }
    }
  }
}

.s-reached-page-top {
  .Header {
    box-shadow: none;
  }
}

.Header {
  background-color: $color-white;

  &__inner {
    max-width: 1440px;
  }

  &__links {
    .Button {
      &:focus-visible, :active{
        .icon-search.icon-after:after {
          background-position-y: -3rem;
        }
      }
    }
  }
}

.overflow-hidden {
  .NavigationScroll {
    top: 0 !important;
  }
}

.Search_overlay.visible {
  position: absolute;
  z-index: 11;
  inset: 0 0 0 0;
  opacity: 0.5;
  background-color: $color-blue-darkest;
}
