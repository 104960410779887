@import 'assets/variables';
@import 'assets/mixins';

.NodeHelpPage {
  &__intro {
    margin-bottom: 6rem;

    @media #{$tablet} {
      margin-bottom: 8rem;
    }
  }

  &__hero {

    @media #{$mobile-only} {
      padding: 0 !important;
    }


    @media #{$tablet} {
      padding: 0 0 0 2rem !important;
    }

    @media #{$normal} {
      left: -8rem;
      padding: 0 2rem !important;
    }

    &__inner {
      padding: 6rem 2rem 12rem 2rem;
      background-color: $color-blue-light;

      @media screen and (width <= 768px) and (width >= 576px) {
        border-radius: 1rem;
      }

      @media #{$tablet} {
        margin-right: -6rem;
        padding: 4rem 12rem 4rem 6rem;
        border-radius: 1rem;
      }

      @media #{$normal} {
        margin-right: -20rem;
        padding: 8rem 22rem 8rem 8rem;
      }

      &--administratie {
        background-color: $color-green-light;
      }

      &--tarieven {
        background-color: $color-turquoise-light;
      }

      &--ppwr {
        background-color: $color-blue-grey-light;
      }
    }

    h1 {
      margin-bottom: 2rem;

      @media #{$normal} {
        margin-bottom: 2.5rem;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 33.5rem;
    object-fit: cover;
    border-radius: 1rem;

    @media #{$mobile-only} {
      position: relative;
      top: -8rem;
      margin-bottom: -8rem;
    }

    @media #{$tablet} {
      height: 22rem;
      margin-top: 4rem;
    }

    @media #{$normal} {
      height: 36rem;
    }

    @media #{$wide} {
      height: 46rem;
    }

    &__wrapper {
      position: relative;
    }

  }


  &__body {
    p {
      margin-bottom: 0;
    }
  }

  &__downloads {
    margin-bottom: 6rem;
  }

  &__paragraphs_wrapper {
    .Container {
      padding: 0;

      .ParagraphCtaBlock__inner {
        @media #{$normal} {
          padding: 12rem 10rem;
        }
      }
    }
  }

  .NodePerson {
    margin-bottom: 6rem;
  }

}
