@import 'assets/variables';
@import 'assets/mixins';

.NodeProduct {
  margin-top: 6rem;

  &__title {
    margin-bottom: 1rem;
  }

  &__alternative_names {
    margin-bottom: 2rem;

    div {
      display: inline-block;
      white-space: pre;
    }
  }

  &__body {
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 3rem;
    }
  }

  &__materials {
    margin-bottom: 4rem;

    div {
      display: inline;
    }
  }

  &__type {


    @include font--secondary;

    padding: 1rem 2rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $color-blue-dark;
    text-decoration: none;
    background-color: $color-green-light;
    border-radius: 5rem;

    transition:
      color .1s linear background-color .1s linear;


    &:hover {
      color: $color-blue-dark;
      text-decoration: none;
      background-color: $color-green-primary;
    }

    &:focus {
      color: $color-white;
      background-color: $color-green-dark;
    }

    &+& {
      margin-left: 1rem;
    }
  }

  &__image {
    width: 100%;
    border-radius: 1rem;

    @media #{$mobile-only} {
      margin-bottom: 6rem;
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    border: 1px solid rgb(0 80 91 / 20%);
    border-radius: 1rem;

    &__item {
      display: flex;
      align-items: center;
      height: 6rem;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgb(0 80 91 / 20%);
      }

      &__title {
        @include font--quarternary;

        flex: 1 1 auto;
        padding: 2rem;
        font-size: 1.6rem;
        color: $color-blue-darkest;
      }

      &__checkmark {
        padding: 1.5rem 2rem;
        border-left: 1px solid rgb(0 80 91 / 20%);

        .icon {
          font-size: 3rem;
        }
      }

    }
  }

  &__note {
    margin-bottom: 4rem;
  }

  &__similar_cases {
    div {
      display: inline-block;
      white-space: pre;
    }
  }
}
