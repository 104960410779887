@import 'assets/variables';
@import 'assets/mixins';

body {
  @include font--primary;

  font-size: $font-size--body;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: $line-height--body;
  color: $color-blue-dark;

  @media #{$normal} {
    font-size: $font-size--body;
    line-height: $line-height--body;
  }
}

a {
  text-decoration: underline;

  &:hover {
    color: $color-blue-primary;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
  color: $color-blue-darkest;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

h1,
.asH1 {
  @include h1;
}

h2,
.asH2 {
  @include h2;
}

h3,
.asH3 {
  @include h3;
}

h4,
.asH4 {
  @include h4;
}
