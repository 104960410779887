@import 'assets/variables';
@import 'assets/mixins';

.Accordion__item {
  border-bottom: 1px solid $color-border;

  &__title {
    margin: 0;
    padding: 2rem 0;

    button {
      @include font--quarternary;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0;

      font-size: 1.6rem !important;
      line-height: 2rem;
      color: inherit;
      text-align: left;

      background: none;
      border: 0;
      outline: inherit;

      .icon {
        transform: rotate(90deg);
        order: 2;
        padding-left: 2rem;
        font-size: 2.4rem !important;
        background-position-x: -2em;
        transition: transform .1s cubic-bezier(0.33, 1, 0.68, 1);
      }
    }
  }

  &__content {
    .WysiwygField {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $color-blue-dark;
    }
  }

  &.collapsed {
    .icon {
      transform: rotate(180deg);
    }
  }
}
