@import 'assets/variables';
@import 'assets/mixins';

.Search__results__header {
  @include font--secondary;

  font-size: 2.2rem;

  &.row {
    @media #{$mobile-only} {
      display: none;
    }
  }
}
