@import "assets/variables";

.ViewCrisisBanner .row {
  flex-wrap: nowrap;
}

.NodeCrisisBanner {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem;

  @media #{$tablet} {
    padding: 2rem 4rem;
  }

  @media #{$normal} {
    padding: 2rem 8rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      color: $color-blue-darkest;
    }

    p {
      margin-bottom: 0;
      font-size: 1.6rem;
    }
  }

  &__close {
    display: flex;
    justify-content: end;

    .Button {
      overflow: visible;
      padding: 0;
      color: $color-blue-darkest;
      background-color: transparent;

      .icon {
        font-size: 3rem;
      }
    }

    .Button:active,
    .Button:focus {
      color: $color-blue-darkest;
      background-color: transparent;
    }
  }

  &__close_content {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    height: 100%;

    @media #{$normal} {
      align-items: center;
    }
  }

  &__close_text {
    @media #{$tablet-down} {
      display: none;
    }
  }
}
