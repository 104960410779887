@import 'assets/variables';
@import 'assets/mixins';

.TaxonomyMaterialTypeTeaser {
  padding: 3rem 0;
  border-bottom: 1px solid $color-border;

  &__body{
    color: $color-blue-dark;
  }

  a:hover,
  a {
    text-decoration: none;
  }

  h3 {
    color: $color-blue-darkest;
  }

  .Lottie {
    @media #{$normal} {
      margin-top: 3rem;
    }
  }
}
