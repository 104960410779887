@import "assets/variables";

.ParagraphInfographic {
  &__container {
    display: flex;
    justify-content: center;
  }

  &__wrapper {
    margin-bottom: 8rem;
    padding-right: 2rem;
    padding-left: 2rem;

    @media #{$normal} {
      padding-right: 0;
      padding-left: 0;
    }
  }
}