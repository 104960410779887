@import 'assets/variables';
@import 'assets/mixins';

.ParagraphImage {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  &__image {
    width: 100%;
    border-radius: 1rem;
  }
}
