@import 'assets/variables';
@import 'assets/mixins';

.ParagraphHighlightIconText {
  margin-bottom: 6rem;

  @media #{$normal} {
    margin-bottom: 8rem;
  }

  &__body {
    @include font--secondary;

    font-size: 2.8rem;
    line-height: 3.8rem;
    color: $color-blue-darkest;
    text-align: center;

    @media #{$tablet} {
      text-align: left;
    }

    @media #{$normal} {
      font-size: 3.8rem;
      line-height: 5.4rem;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;

    @media #{$tablet} {
      margin-bottom: 0;
    }

    @media #{$normal} {
      height: 27rem;
    }
  }

  &--right {
    .ParagraphHighlightIconText__image__wrapper {
      @media #{$tablet} {
        order: 2;
      }
    }
  }

  &--top {
    .ParagraphHighlightIconText__body {
      text-align: center;
    }

    .ParagraphHighlightIconText__image {
      margin-bottom: 2rem;

      @media #{$normal} {
        margin-bottom: 4rem;
      }
    }
  }

  .highlight {
    &__highlighted {
      @include font--secondary;

      font-size: 3.8rem;
      line-height: 3.8rem;
      color: $color-blue-primary;

      @media #{$normal} {
        font-size: 5.4rem;
        line-height: 5.4rem;
      }
    }
  }
}
