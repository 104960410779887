@import 'assets/variables';
@import 'assets/mixins';

.TaxonomyMaterialType {

  margin-top: 6rem;

  @media #{$tablet} {
    margin-top: 10rem;
  }

  @media #{$normal} {
    margin-top: 12rem;
  }

  &__header {
    margin-bottom: 6rem;
    padding: 4rem 2rem 6rem 2rem;
    background: #D8F5F4;
    border-radius: 2rem;

    @media #{$tablet} {
      margin-bottom: 8rem;
      padding: 6rem 6rem 6rem 4rem;
    }

    @media #{$normal} {
      margin-bottom: 12rem;
      padding: 8rem 10rem;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media #{$tablet} {
        flex-direction: row;
      }
    }
  }

  &__title {
    @media #{$normal} {
      margin-bottom: 2.5rem;
    }
  }

  &__body {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.8rem;

    @media #{$normal} {
      font-size: 2rem;
      line-height: 3.2rem;
    }

    p {
      margin-bottom: 0;
    }
  }

  &__image {
    width: 12rem;
    height: 12rem;
    margin-bottom: 4rem;
    object-fit: cover;

    @media #{$tablet} {
      margin-right: 4rem;
      margin-bottom: 0;
    }

    @media #{$normal} {
      width: 16rem;
      height: 16rem;
      margin-right: 8rem;
    }
  }

}
