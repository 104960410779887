@import 'assets/variables';
@import 'assets/mixins';

.ParagraphTextImage {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  &__inner {
    &__content {

      @media #{$tablet} {
        &__image {
          align-self: center;
          margin-bottom: 0;
        }
      }

      @media #{$normal} {
        &__image {
          align-self: center;
          margin-bottom: 0;
        }
      }

      &__image {
        width: 100%;
        height: 100%;
        margin-bottom: 1.8rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
          overflow-wrap: break-word;
          object-fit: cover;
          object-fit: contain;
          border-radius: 1rem;
        }
      }

      a {
        color: $color-blue-primary;
      }
    }

    &__button .Button {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
