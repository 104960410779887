@import 'assets/variables';
@import 'assets/mixins';


@keyframes link-active-animation {
  0% {
    transform-origin: right top;
    transform: scaleX(1);
  }

  50% {
    transform-origin: right top;
    transform: scaleX(0);
  }

  51% {
    transform-origin: left top;
  }

  100% {
    transform: scaleX(1);
  }
}

.Footer {
  border-top: 1px solid $footer-border;

  // TEMP Quoted because font wont render.
  // font-family: $font--quinary;
  &__newsletter__wrapper {
    padding: 8rem 0;

    &__wrapper {
      background-color: $color-white;
    }
  }

  &__main {
    padding: 4rem 0;

    @media #{$tablet} {
      padding: 6rem 0;
    }

    &__wrapper {
      background-color: rgba($color: $color-blue-light, $alpha: 30%);
    }

    &__logo {
      width: fit-content;


      @media #{$tablet} {
        margin-bottom: 6rem;
      }

      &__text {
        width: 9.1rem;
        height: 5rem;
        background-image: url('../../assets/images/logo/afvalfonds-woordmerk.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @media #{$tablet} {
          width: 10.9rem;
          height: 6rem;
        }

        @media #{$normal} {
          width: 12.7rem;
          height: 7rem;
        }
      }

      a {
        display: flex;
        width: fit-content;
        margin-bottom: 4rem;
      }
    }
  }

  &__menu__item {
    margin-bottom: 4rem;

    @media #{$tablet} {
      margin-bottom: 6rem;
    }
  }

  &__super_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media #{$tablet} {
      flex-direction: row;
      justify-content: space-between;
    }

    .Menu {
      display: flex;
      flex-direction: column;

      @media #{$tablet-only} {
        flex-direction: column;
        align-items: flex-end;
      }

      @media #{$normal} {
        flex-direction: row;
      }

      .FooterSubMenu__item {
        margin-bottom: 1rem;

        @media #{$normal} {
          margin-right: 4rem;
          margin-bottom: 0;

          &:last-of-type {
            margin-right: 0;
          }
        }

        .FooterSubMenu__item__link,
        .FooterSubMenu__item__title {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  h2 {
    margin-bottom: 0;
  }

  .FooterMenu {
    &__item {
      &__title {
        margin-bottom: 2rem;
      }
    }
  }

  .FooterSubMenu {
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      @media #{$mobile-only} {
        font-size: 1.6rem;
        line-height: 2rem;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      // Icon.
      &:before {
        margin-right: 1.5rem;
        font-size: 2.4rem;
      }

      &__link {
        color: $color-blue-darkest;
        text-decoration: none;

        &:hover {
          text-decoration: none;

          .FooterSubMenu__item__title {
            &:after {
              @include link-underline-animation-hover;
            }
          }
        }
      }

      &__title {
        position: relative;

        &:after {
          @include link-underline-animation;
        }
      }

      &--active {
        .FooterSubMenu__item__title {
          position: relative;

          &:after {
            @include link-underline-animation;
            @include link-underline-animation-hover;
          }
        }

        .FooterSubMenu__item__link {
          &:hover {
            .FooterSubMenu__item__title {
              &:after {
                animation: link-active-animation .3s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
              }
            }
          }
        }
      }
    }
  }

  .FooterAddress {
    margin-bottom: 4rem;
    padding: 2rem;
    background-color: $color-blue-light;
    border-radius: 10px;

    @media #{$tablet} {
      margin-bottom: 2rem;
      padding: 2rem 4rem;
    }

    @media #{$wide} {
      padding: 2rem 12rem;
    }

    .Menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @media #{$normal} {
        flex-direction: row;
        align-items: center;
      }

      .FooterSubMenu__item {

        margin-bottom: 1.5rem;

        @media #{$normal} {
          margin-bottom: 0;
        }

        &:last-of-type {
          margin-bottom: 0;

        }
      }
    }
  }

}
