@import 'assets/variables';
@import 'bootstrap/scss/mixins';

// -----------------------------------------------------------------------------
//
// Input styling
//
// -----------------------------------------------------------------------------
body .TextField,
body .WebformTextField .TextField {
  position: relative;

  input {
    height: 5rem;
    color: $color-blue-darkest;
    border: 1px solid $color-blue-primary;
    border-radius: 25px;

    &:focus {
      border: solid 1px $color-blue-dark;
      outline: none;
    }

    &::placeholder {
      color: $color-blue-darkest;
      opacity: 0.5;
    }

    &.error {
      border: .1rem solid $color-system-error !important;
    }

    &.error~.FormElement__error:after {
      margin-left: 11rem !important;
    }

    [type="number"] {
      padding-right: 2.5rem;
    }
  }
}

.Checkbox {
  label {
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }

  .icon-checkmark.icon-before:before {
    margin-right: 1rem;
    background-position-x: -23em;
    background-position-y: 0;
  }

  .icon-variant-1.icon-before:before {
    background-position-x: -23em;
    background-position-y: -1em;
  }
}

.RadioButtons {
  label {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:before {
      margin-right: 0.5rem;
    }

    &>input {
      display: none;
    }
  }
}

.WebformCaptcha {
  margin-bottom: 3rem;
}

.WebformComponent__submit button {
  box-sizing: border-box;
  padding: 0 2.5rem;
  font-size: 1.6rem;
  line-height: 5rem;
  color: $color-blue-darkest;
  text-decoration: none;

  background-color: $color-blue-primary;
  border-radius: 50px;


  &:hover {
    background-color: $color-blue-light;
  }

}

// TODO: We need to be able to theme this better...
.Select {
  &__select {
    width: 100%;
    min-width: 200px;

  }

  &__inner__indicator-separator {
    display: none !important;
  }

  &__inner__control {
    height: 5rem;
    padding: 0 1rem;
    border-color: $color-blue-primary !important;
    border-radius: 25px !important;
    transition: none !important;

    &:hover {
      border-color: $color-blue-primary !important;
    }

    &--menu-is-open {
      border-bottom-color: transparent !important;
      border-radius: 25px 25px 0 0 !important;

      &:hover {
        border-bottom-color: transparent !important;
      }
    }
  }

  &__inner__menu {
    border-color: transparent;
    border-radius: 0 0 25px 25px !important;
  }
}

.FormElement>label,
.WebformFileUpload>label {
  font-size: 1.6rem;
  line-height: 2rem;
}
