@import "assets/variables";
@import "assets/mixins";

.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 1.5rem 0;

  @media #{$normal} {
    height: 8rem;
  }

  &__container {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin: 0 0.25rem;
    font-weight: 600;

    &:not(.previous, .next):hover {
      background-color: $color-blue-light;
      border-radius: 50%;
    }

    &.previous:hover {
      transform: translateX(-0.25rem);
      transition: 100ms
    }

    &.next:hover {
      transform: translateX(0.25rem);
      transition: 100ms
    }

    &.active {
      background-color: $color-blue-primary;
      border-radius: 50%;

      &:hover {
        a {
          color: $color-blue-darkest;
        }
      }

      a {
        color: $color-blue-darkest;
      }
    }

    &.Pagination__break a {
      pointer-events: none;
    }

    a:not([href]) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      color: $color-blue-darkest;
    }
  }

  .previous {
    margin-right: 4rem;
  }

  .next {
    margin-left: 4rem;
  }

  .previous,
  .next {
    align-self: center;

    &.disabled{
      opacity: .5;

      &:hover {
        a {
          cursor: inherit;
          user-select: none;
        }
      }
    }

    a {
      padding: 0 !important;
      text-decoration: none;
    }

    span {
      @media #{$mobile-only} {
        display: none;
      }
    }

    .icon-after:after,
    .icon-before:before{
      font-size: 3rem;
    }
  }

  .previous .icon-before:before {
    top: -2px;
    transform: rotate(180deg);
  }

}
