@import 'assets/variables';
@import 'assets/mixins';

.NodeHome {
  &__hero {
    position: relative;
    overflow: hidden;
    height: 62rem;
    margin-bottom: 6rem;
    background: linear-gradient(62deg, rgb(0 46 52 / 76%) 10.82%, rgb(0 46 52 / 56%) 28.4%, rgb(0 46 52 / 36%) 41.76%, rgb(0 136 154 / 0%) 58.21%);

    @media #{$tablet} {
      height: 48rem;
      margin-bottom: 8rem;
      background: linear-gradient(62deg, rgb(0 46 52 / 76%) 10.82%, rgb(0 46 52 / 56%) 28.4%, rgb(0 46 52 / 36%) 41.76%, rgb(0 136 154 / 0%) 58.21%);
    }

    @media #{$normal} {
      height: 56rem;
      margin-bottom: 12rem;
      background: linear-gradient(69deg, rgb(0 46 52 / 76%) 11.91%, rgb(0 46 52 / 56%) 35.92%, rgb(0 46 52 / 36%) 47.14%, rgb(0 136 154 / 0%) 65.89%);
    }

    @media #{$wide} {
      height: 72rem;
      background: linear-gradient(67deg, rgb(0 46 52 / 85%) 8.77%, rgb(0 46 52 / 63%) 27.88%, rgb(0 46 52 / 40%) 45.75%, rgb(0 136 154 / 0%) 63.47%);
    }

    &__bar {
      position: absolute;
      top: 42rem;
      left: 0;
      width: 100%;
      height: 20rem;
      background: $color-blue-darkest;

      @media #{$tablet} {
        top: 42rem;
        height: 40rem;
      }

      @media #{$normal} {
        height: 0;
      }
    }

    &__image {
      position: absolute;
      z-index: -2;
      width: 100%;
      height: 42rem;
      background-color: $color-blue-light;

      @media #{$normal} {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .container {
      display: flex;
      align-items: end;
      height: 100%;
    }
  }

  &__shadow {
    position: absolute;
    z-index: -1;
    top: 22rem;
    left: 0;
    width: 100%;
    height: 20rem;
    background: linear-gradient(0deg, #002E34 0%, rgb(0 46 52 / 0%) 100%);

    @media #{$tablet} {
      background: linear-gradient(0deg, #002E34 0%, rgb(0 46 52 / 0%) 100%);
    }

    @media #{$normal} {
      display: none;
    }
  }

  &__title {
    margin-bottom: 2rem;
    font-size: 3.8rem;
    font-weight: 700;
    line-height: 4rem;
    color: $color-white;
    filter: drop-shadow(0.6rem 0.3rem 0.7rem $color-blue-dark);

    @media #{$tablet} {
      margin-top: 0;
    }

    @media #{$normal} {
      margin-bottom: 2.5rem;
      font-size: 5.6rem;
      line-height: 5.8rem;
    }
  }

  &__body {
    @include body--large;

    margin-bottom: 2rem;
    font-weight: 600;
    color: $color-white;
    filter: drop-shadow(0.6rem 0.3rem 0.7rem $color-blue-dark);

    @media #{$normal} {
      margin-bottom: 3rem;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    @media #{$tablet} {
      flex-direction: row;
      margin-bottom: 4rem;

      >a:first-of-type {
        margin-right: 2rem;
      }
    }

    @media #{$normal} {
      margin-bottom: 10rem;
    }

    .Button {
      @media #{$mobile-only} {
        justify-content: center;
        width: 100%;
      }

      &:hover {
        span {
          filter: drop-shadow(0 0 0 #0009);
        }
      }

      &:first-of-type {
        @media #{$mobile-only} {
          margin-bottom: 1.5rem;
        }
      }

      &--inverted {
        color: $color-black;
        background-color: $color-white;
      }
    }
  }
}
