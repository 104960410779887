@use "sass:map";

// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
);

$container-max-widths: (
  sm: 540px,
  md: 760px,
  lg: 960px,
  xl: 1200px,
);

$grid-gutter-width-base: 40px;
$grid-xs-gutter-width-base: 40px;
$grid-sm-gutter-width-base: 20px;
$grid-md-gutter-width-base: 20px;
$grid-lg-gutter-width-base: 40px;
$grid-xl-gutter-width-base: 40px;

$grid-gutter-widths: (
  xs: $grid-xs-gutter-width-base,
  sm: $grid-sm-gutter-width-base,
  md: $grid-md-gutter-width-base,
  lg: $grid-lg-gutter-width-base,
  xl: $grid-xl-gutter-width-base,
);

$screen-sm: #{map.get($grid-breakpoints, sm)};
$screen-md: #{map.get($grid-breakpoints, md)};
$screen-lg: #{map.get($grid-breakpoints, lg)};
$screen-xl: #{map.get($grid-breakpoints, xl)};

$screen-phone: $screen-sm;
$screen-tablet: $screen-md;
$screen-desktop: $screen-lg;
$screen-lg-desktop: $screen-xl;

$screen-xs-max: #{map.get($grid-breakpoints, sm) - 1};
$screen-sm-max: #{map.get($grid-breakpoints, md) - 1};
$screen-md-max: #{map.get($grid-breakpoints, lg) - 1};
$screen-lg-max: #{map.get($grid-breakpoints, xl) - 1};

// Lazy media query variables.
$mobile: 'screen and (min-width: #{$screen-sm})';
$tablet: 'screen and (min-width: #{$screen-md})';
$normal: 'screen and (min-width: #{$screen-lg})';
$wide: 'screen and (min-width: #{$screen-xl})';
$mobile-only: 'screen and (max-width: #{$screen-sm-max})';
$tablet-only: 'screen and (min-width: #{$screen-md}) and (max-width: #{$screen-md-max})';
$normal-only: 'screen and (min-width: #{$screen-lg}) and (max-width: #{$screen-lg-max})';
$mobile-down: 'screen and (max-width: #{$screen-sm-max})';
$tablet-down: 'screen and (max-width: #{$screen-md-max})';
$normal-down: 'screen and (max-width: #{$screen-lg-max})';

// -----------------------------------------------------------------------------
//
// Spacing
//
// -----------------------------------------------------------------------------

$spacers: ();
$spacers: map.merge(
  (
    0: 0,
    1: 8px,
    2: 16px,
    3: 24px,
    4: 32px,
    5: 40px,
    6: 48px,
    7: 56px,
    8: 64px,
    9: 72px,
  ),
  $spacers
);

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

// Primary fonts will be used for 90% of the text styles, mainly body text.
// Secondary fonts will be used mainly for headings.
$font--primary: 'Open Sans', helvetica, arial, sans-serif;
$font--secondary: 'Lufga-Bold', helvetica, arial, sans-serif;
$font--tertiary: 'Lufga-Medium', helvetica, arial, sans-serif;
$font--quaternary: 'Lufga-SemiBold', helvetica, arial, sans-serif;
$font--quinary: 'Lufga-Regular', helvetica, arial, sans-serif;

// DESKTOP: Font sizes (in pixels) / line-heights
$desktop--font-size--h1: 4.8rem;
$desktop--line-height--h1: 58px;

$desktop--font-size--h2: 3.8rem;
$desktop--line-height--h2: 44px;

$desktop--font-size--h3: 2.8rem;
$desktop--line-height--h3: 34px;

$desktop--font-size--h4: 2.2rem;
$desktop--line-height--h4: 26px;

$desktop--font-size--intro: 2rem;
$desktop--line-height--intro: 32px;

// MOBILE: Font sizes (in pixels) / line-heights
$font-size--h1: 3.4rem;
$line-height--h1: 40px;

$font-size--h2: 2.8rem;
$line-height--h2: 34px;

$font-size--h3: 2.4rem;
$line-height--h3: 28px;

$font-size--h4: 2rem;
$line-height--h4: 24px;

$font-size--body: 1.8rem;
$line-height--body: 28px;

$font-size--body--large: 1.8rem;
$line-height--body--large: 28px;

$desktop--font-size--body--large: 2.0rem;
$desktop--line-height--body--large: 32px;

$font-size--body--small: 1.6rem;
$line-height--body--small: 24px;

$font-size--link: 1.6rem;
$line-height--link: 20px;

$font-size--link--big: 2.4rem;
$line-height--link--big: 28px;

$font-size--caption: 1.4rem;
$line-height--caption: 1.8rem;

// -----------------------------------------------------------------------------
//
// Colors
//
// -----------------------------------------------------------------------------
$color-green-light: #EEF9E0;
$color-green-primary: #8ED92F;
$color-green-dark: #4D721F;

$color-turquoise-light: #E6F7EE;
$color-turquoise-primary: #59C98C;
$color-turquoise-dark: #206F43;

$color-blue-light: #D8F5F4;
$color-blue-primary: #00BED8;
$color-blue-dark: #00505B;
$color-blue-darkest: #002E34;
$color-blue-verpact: #00ABDD;

$color-blue-grey-light: #EFFAFA;
$color-blue-grey: #CFE7EA;

$color-white: #fff;
$color-black: #000;
$color-grey: #808080;

$color-border: #EFEFEF;

$color-system-notification-light: #F3FDF8;
$color-system-notification: #10DB79;

$color-system-warning-light: #FFFBF6;
$color-system-warning: #FFB546;

$color-system-error-light: #FFF5F6;
$color-system-error: #FF2E47;

$color-shadow: rgb(0 80 91 / 20%);

// -----------------------------------------------------------------------------
//
// Margins
//
// -----------------------------------------------------------------------------
$paragraph-bottom-margin: 6rem;
$paragraph-bottom-margin-tablet: 8rem;
$paragraph-bottom-margin-desktop: 12rem;

// -----------------------------------------------------------------------------
//
// Borders
//
// -----------------------------------------------------------------------------
$menu-border: 0 2px 6px $color-shadow;
$footer-border: #CCDCDE;

// -----------------------------------------------------------------------------
//
// Shadows
//
// -----------------------------------------------------------------------------

// To be added.

// -----------------------------------------------------------------------------
//
// Menu height
//
// -----------------------------------------------------------------------------
$menu-height-mobile: 6rem;
$menu-height-tablet: 10rem;
$menu-height-desktop: 12rem;

// -----------------------------------------------------------------------------
//
// z-index
//
// -----------------------------------------------------------------------------
$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 100;
$z-index-5: 1000;

// -----------------------------------------------------------------------------
//
// Animation
//
// -----------------------------------------------------------------------------

// To be added.

// -----------------------------------------------------------------------------
//
// Icongrid
//
// ---------
// Define the amount of rows.
$rows: 7;

// Define the icons from left to right in the icongrid using a human-readable
// name. This will also be part of the classname.
// NOTE: If you add a row or a icon, also check languageswitcher component scss.
$icon-names: (
  search,
  cross,
  chevron,
  arrow,
  plus,
  minus,
  phone,
  mail,
  location,
  download,
  send,
  play,
  twitter,
  linkedin,
  english,
  dutch,
  checkmark,
  pause,
  volume_full,
  volume_half,
  volume_mute,
  fullscreen,
  fullscreen_close,
  checkbox,
  radio,
  blog,
  video,
  interview,
  podcast,
  persbericht,
  plastic,
  glas,
  papier_karton,
  hout,
  metaal,
  dot,
  rss
);
