@import "assets/variables";
@import "assets/mixins";

body .Button {
  @include font--secondary;

  position: relative;

  overflow: hidden;
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  border: $color-white;


  &--primary {
    @include button-primary-variant;
  }

  &--inverted {
    box-sizing: border-box;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-light;
    text-decoration: none;
    background-color: transparent;
    border: 3px solid $color-blue-light;
    border-radius: 50px;

    transition:
      background-color 0.33s cubic-bezier(0.1, 0, 0, 1),
      border-color 0.33s cubic-bezier(0.1, 0, 0, 1),
      color 0.33s cubic-bezier(0.1, 0, 0, 1);

    &:hover {
      color: $color-blue-darkest;
      text-decoration: none;
    }

    &:active,
    &:focus-visible {
      color: $color-white;
      text-decoration: none;
      background-color: $color-blue-darkest;
      border: 3px solid $color-blue-darkest;

      .icon {
        background-position-y: -2.5rem;
      }

      .Button__circle {
        background-color: $color-blue-darkest;
        box-shadow: 0 0 0 150px $color-blue-darkest;
      }
    }

  }

  &--secondary {
    box-sizing: border-box;
    padding: 1.2rem 2.5rem;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-blue-darkest;
    text-decoration: none;
    background-color: transparent;
    border: 3px solid $color-blue-light;
    border-radius: 50px;

    transition:
      background-color 0.33s cubic-bezier(0.1, 0, 0, 1),
      border-color 0.33s cubic-bezier(0.1, 0, 0, 1),
      color 0.33s cubic-bezier(0.1, 0, 0, 1);

    &:hover {
      color: $color-blue-darkest;
      text-decoration: none;
    }

    &:active,
    &:focus-visible {
      color: $color-white;
      text-decoration: none;
      background-color: $color-blue-darkest;
      border: 3px solid $color-blue-darkest;

      .icon {
        background-position-y: -2.5rem;
      }

      .Button__circle {
        background-color: $color-blue-darkest;
        box-shadow: 0 0 0 150px $color-blue-darkest;
      }
    }
  }

  &--tertiary {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: $color-blue-primary;
    background-color: transparent;

    &:hover,
    &:focus-visible {
      color: $color-blue-dark;
      text-decoration: none;
      background-color: transparent;
    }
  }

  .Button__inner {
    z-index: 10;
  }
}

.Button__circle {
  pointer-events: none;
  content: "";
  position: absolute;
  z-index: 9;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 1px;
  height: 1px;
  background-color: $color-blue-light;
  border-radius: 50%;
  transition: transform 0.5s cubic-bezier(0.2, 0, 0.2, 1);
}

.Button__lottie {
  width: 3rem;
  height: 3rem;
  margin-left: 1.5rem;
}
