@import 'assets/variables';
@import 'assets/mixins';

.NodeHelpPageTeaser {
  margin-bottom: 4rem;

  &__inner {
    position: relative;
    overflow: hidden;
    height: 17rem;
    padding: 2rem;
    background-color: $color-blue-light;
    border-radius: 1rem;

    transition: background-color .33s cubic-bezier(.1, 0, 0, 1);

    @media #{$tablet} {
      height: 20rem;
    }

    @media #{$normal} {
      height: 24rem;
      padding: 3rem;
    }

    &:hover {
      background-color: $color-blue-primary;

      .NodeHelpPageTeaser__circle {
        box-shadow: 0 0 0 300px $color-blue-primary;
      }
    }

    &.NodeHelpPageTeaser__inner--administratie {
      background-color: $color-green-light;

      &:hover {
        background-color: $color-green-primary;

        .NodeHelpPageTeaser__circle {
          box-shadow: 0 0 0 300px $color-green-primary;
        }
      }
    }

    &.NodeHelpPageTeaser__inner--tarieven {
      background-color: $color-turquoise-light;

      &:hover {
        background-color: $color-turquoise-primary;

        .NodeHelpPageTeaser__circle {
          box-shadow: 0 0 0 300px $color-turquoise-primary;
        }
      }
    }

    &.NodeHelpPageTeaser__inner--ppwr {
      background-color: $color-blue-grey-light;

      &:hover {
        background-color: $color-blue-grey;

        .NodeHelpPageTeaser__circle {
          box-shadow: 0 0 0 300px $color-blue-grey;
        }
      }
    }
  }

  &__title {
    position: absolute;
    z-index: 10;
    right: 2rem;
    left: 2rem;

    @media #{$normal} {
      right: 3rem;
      left: 3rem;
    }
  }

  &__lottie {

    position: absolute;
    z-index: 10;
    right: 2rem;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: $color-white;
    border-radius: 50%;

    @media #{$normal} {
      right: 3rem;
      bottom: 3rem;

      width: 6rem;
      height: 6rem;
    }

    &__lottie {
      width: 3rem;
      height: 3rem;
    }
  }

  &__circle {
    content: '';
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
    opacity: 1;
    border-radius: 50%;
    transition: box-shadow .33s cubic-bezier(.1, 0, 0, 1);
  }

  &__link {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    inset: 0 0 0 0;
  }
}
