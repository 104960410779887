@import 'assets/variables';
@import 'assets/mixins';

.ParagraphFaqItems{
  margin-bottom: 6rem;

  @media #{$normal} {
    margin-bottom: 8rem;
  }

  .Accordion__item {
    &:last-of-type {
      border-bottom: 0;
    }

    &__title {

      @media #{$normal} {
        padding: 3rem 0;
      }

      button {

        @include h4;

        width: 100%;
        margin-bottom: 0;
        padding: 0;
        color: inherit;

        background: none;
        border: 0;
        outline: inherit;
      }
    }

    .WysiwygField {
      font-size: 1.8rem;
    }
  }

}
