@import "assets/variables";

.InfographicItem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem 0 3rem 0;
  border-bottom: 1px solid $color-border;

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__image {
    width: 6rem;
    min-width: 6rem;
    height: 6rem;
    min-height: 6rem;
    margin-right: 2rem;

    @media #{$normal} {
      margin-right: 4rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__button {
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    min-height: 3rem;
    margin-right: 1.5rem;

    @media #{$normal} {
      position: static;
      margin-right: 1.5rem;
    }
  }
}