@import 'assets/variables';
@import 'assets/mixins';

.ParagraphCtaBlock {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  &__inner {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12rem 2rem;

    background-image: url('../../../assets/images/CTA-BG-mobile.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 1rem;

    @media #{$tablet} {
      padding: 12rem 6rem;
      background-image: url('../../../assets/images/CTA-BG-desktop.svg');
      border-radius: 2rem;
    }

    @media #{$normal} {
      padding: 12rem 20rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
    color: $color-white;
    text-align: center;

    @media #{$normal} {
      margin-bottom: 2.5rem;
    }
  }

  .Button {
    background-color: $color-white;

    &:hover {
      background-color: $color-blue-light;
    }

    &:focus {
      background-color: $color-blue-dark;
    }
  }
}
