@import "assets/variables";

.Modal {
  position: fixed;
  z-index: 1100;
  inset: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__backdrop {
    cursor: pointer;
    position: absolute;
    z-index: -5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: $color-blue-darkest;
  }

  &__inner {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 116rem;
    max-height: 90vh;
    margin: 2rem;
    padding: 2rem;
    background-color: $color-white;
    border-radius: 3rem;
  }

  &__content {
    overflow: hidden;
    display: flex;

    &__inner {
      overflow: hidden auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5rem;
      margin-right: 1.5rem;
      margin-left: 1.5rem;
      padding-bottom: 4rem;

      @media #{$tablet} {
        padding-right: 8rem;
        padding-left: 8rem;
      }

      @media #{$normal} {
        padding-right: 16rem;
        padding-left: 16rem;
      }

      &__image {
        width: auto;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        margin-top: 3rem;

        @media #{$tablet} {
          max-height:38rem
        }
      }

      div {
        font-weight: 500;
      }

      h2 {
        margin-top: 2rem;
      }
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 3.5rem;
    right: 3.5rem;
    display: inline-block;
    font-size: 2rem;
  }

  &__title {
    margin-bottom: 1rem;
  }
}
