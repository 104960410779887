@import 'assets/variables';
@import 'assets/mixins';

.ViewCatalog {
  margin: 12rem 0;

  @media #{$mobile-only} {
    margin-top: 0;
  }

  &--loading {
    opacity: 0.25;
  }
}
