@import 'assets/variables';
@import 'assets/mixins';

.View__filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4rem;

  @media #{$tablet} {
    flex-flow: row wrap;
  }

  @media #{$wide} {
    flex-wrap: nowrap;
  }

  // So many exceptions due to responsiveness...
  .ViewFilters__filter {
    width: 100%;
    margin-top: 1rem;

    @media #{$mobile-only} {
      &:not(:first-child) > .FormElement {
        margin-top: .5rem;
      }
    }

    @media #{$tablet} {
      width: auto;

      &:first-child {
        flex-basis: 100%;
      }

      &:not(:first-child, :last-child) {
        margin-right: 0;
      }
    }

    @media #{$normal} {
      &:not(:first-child, :last-child) {
        margin-right: 2rem;
      }
    }

    @media #{$wide} {
      &:first-child {
        flex-basis: auto;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  .FormElement {
    margin: 0;
  }

  .Checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 5rem;
    margin-top: 2.5rem;
    padding: 0 2rem;
    border: 1px solid $color-blue-primary;
    border-radius: 25px;

    &__inner {
      width: 100%;
    }

    label {
      @include body--small;

      margin: 0;
      font-weight: normal;
    }

    .icon-variant-1.icon-before:before {
      background-position-y: -3em;
    }
  }
}
