@import 'assets/variables';
@import 'assets/mixins';

.NodeBasicPage {
  @media #{$tablet} {
    margin-top: 8rem;
  }

  &__title {
    margin-bottom: 6rem;

    @media #{$normal} {
      margin-bottom: 12rem;
    }

    h1 {
      margin-bottom: 0;

      @media #{$normal} {
        margin-bottom: 0;
      }
    }
  }

  &__body {
    margin-top: 2rem;

    p:first-child {
      @include body--large;
    }

    p:last-child {
      margin-bottom: 0;
    }

    a {
      color: $color-blue-primary;
    }
  }
}
