@import 'assets/variables';
@import 'assets/mixins';

.LandingPageHeroWithVideo {
  margin-bottom: 6rem;

  @media #{$normal} {
    margin-bottom: 8rem;
  }

  &__title {
    color: $color-blue-dark;
    text-align: center;

    @media #{$tablet} {
      margin-bottom: 2rem;
      color: $color-white;
    }

    @media #{$normal} {
      margin-bottom: 2.5rem;
    }

    &__wrapper {
      @media #{$tablet} {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;
        width: 46rem;
      }

      @media #{$normal} {
        width: 76rem;
      }
    }

  }

  &__lottie__wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    border-radius: 2rem;
  }

  &__hero {
    position: relative;
    z-index: 1;
    aspect-ratio: 16 / 9;
    margin-bottom: 6rem;

    @media #{$normal} {
      margin-right: -8rem;
      margin-bottom: 8rem;
      margin-left: -8rem
    }

    &__hero {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;
    }

    &__image_wrapper {
      height: 100%;
      opacity: 0.5;
      background: #00505B;

      &:after {
        content: '';
        position: absolute;
        inset: 0 0 0 0;
        opacity: 0.5;
        background: #00505B;
        border-radius: 1rem;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
    }

    .Lottie__landing_page {
      &--left,
      &--right {
        cursor: initial;
        top: 0;
        bottom: 0;
        width: 15%;
        height: 100%;

        svg {
          border-radius: 2rem;
        }
      }

      &--left {
        position: absolute;
        z-index: 10;
        left: 0;
      }

      &--right {
        position: absolute;
        z-index: 10;
        right: 0;
      }
    }

    .lazyload-wrapper,
    .LazyLoadWrapper {
      height: 100%;
    }
  }

  &__body {

    margin-bottom: 6rem;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3.2rem;
    color: $color-blue-dark;

    @media #{$tablet} {
      margin-bottom: 8rem;
      text-align: center;
    }

    @media #{$normal} {
      margin-bottom: 12rem;
    }
  }

  &--button_clicked {
    .LandingPageHeroWithVideo__title {
      @media #{$tablet} {
        display: none;
      }
    }

    .LandingPageHeroWithVideo__hero__hero,
    .Lottie,
    .Button,
    .LandingPageHeroWithVideo__hero__image_wrapper {
      display: none;
    }
  }

  .col-12 {
    @media #{$tablet-only} {
      padding: 0 1rem !important;
    }
  }

  .Button {
    font-size: 0;
    line-height: 1;

    @media #{$mobile-only} {
      position: absolute;
      z-index: 10;
      top: 63%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
    }

    @media #{$tablet} {
      font-size: 1.8rem;
    }

    div {
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 2.8rem;

      @media #{$tablet} {
        margin-left: 1rem;
        font-size: 2.4rem;
      }
    }
  }

}
