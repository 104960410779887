@import 'assets/variables';
@import 'assets/mixins';

.ParticipantsSearchForm {
  margin-bottom: 4rem;

  b {
    @include font--secondary;
  }

  .Select__select {
    min-width: 150px;
  }

  .button-holder a {
    position: absolute;
    top: 0;
    bottom: initial;
    margin-top: 3rem;
  }
}

