@import 'assets/variables';
@import 'assets/mixins';

.SearchResult {
  padding: 4rem 0;
  border-bottom: 1px solid $color-border;

  a {
    text-decoration: none;

    h2 {
      @include h4;

      color: $color-blue-darkest;
    }
  }

  & > div {
    margin-bottom: 1rem;
  }
}
