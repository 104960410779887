@import 'assets/variables';
@import 'assets/mixins';

// responsive_table_filter module css

.responsive-figure-table {
  overflow-x: auto;
  max-width: 100%;
  margin: 0;
}

// WysiwygField table

.WysiwygField table {
  border-spacing: 0;
  border-collapse: separate;
  min-width: 100%;
  font-size: 1.6rem;
  border: 1px solid $color-blue-grey;
  border-radius: 1rem;

  thead {
    background-color: rgb(222 244 244);

    // header row dark border bottom and padding
    th {
      padding: 3rem 1.5rem 3rem 1.5rem;
      border-bottom: 1px solid #002e34;
    }

    // touching cells light border right
    th:not(:last-child) {
      border-right: 1px solid $color-blue-grey;
    }

    // last header cell 
    th:last-child {
      border-radius: 0 1rem 0 0;
    }

    // first header cell dark border right and radius
    th:nth-child(1) {
      border-right: 1px solid #002e34;
      border-radius: 1rem 0 0 0;
    }
  }

  tbody {
    // first column cells dark border right
    th {
      border-right: 1px solid #002e34;
    }

    // touching cells light border bottom except last 
    tr:not(:last-child)>th,
    tr:not(:last-child)>td {
      border-bottom: 1px solid $color-blue-grey;
    }

    // touching cells light border right except last 
    td:not(:last-child) {
      border-right: 1px solid $color-blue-grey;
    }

    // padding for all rows except first
    th,
    td {
      padding: 1.5rem 1rem 1.5rem 1rem;
    }
  }
}
