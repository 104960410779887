@import 'assets/variables';
@import 'assets/mixins';

.LandingPageHeroWithImage {
  &__title {
    color: $color-blue-dark;
    text-align: center;

    @media #{$tablet} {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 46rem;
      color: $color-white;
    }

    @media #{$normal} {
      width: 76rem;
    }
  }

  &__lottie__wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    background-color: $color-blue-light;
    border-radius: 2rem;

    @media #{$tablet} {
      &:after {
        content: '';
        position: absolute;
        inset: 0 0 0 0;
        opacity: 0.5;
        background: #00505B;
        border-radius: 1rem;
      }
    }
  }

  &__hero {
    position: relative;
    z-index: 1;
    margin-bottom: 4rem;


    @media #{$tablet} {
      height: 30rem;
    }

    @media #{$normal} {
      height: 40rem;
      margin-right: -8rem;
      margin-bottom: 8rem;
      margin-left: -8rem
    }

    &__hero {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1rem;

      @media #{$mobile-only} {
        height: 20rem;
      }
    }

    img {
      height: 100%;
    }

    .Lottie__landing_page {
      &--left,
      &--right {
        cursor: initial;
        top: 0;
        bottom: 0;
        width: 15%;
        height: 100%;

        svg {
          border-radius: 2rem;
        }
      }

      &--left {
        position: absolute;
        z-index: 10;
        left: 0;
      }

      &--right {
        position: absolute;
        z-index: 10;
        right: 0;
      }
    }
  }

  &__body {
    margin-bottom: 6rem;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3.2rem;
    color: $color-blue-dark;
    text-align: center;

    @media #{$tablet} {
      margin-bottom: 8rem;
      text-align: center;
    }

    @media #{$normal} {
      margin-bottom: 12rem;
    }

    &--with-anchors {
      text-align: left;
    }
  }

  &__anchors {
    height: 100%;
    padding: 4rem 2rem;
    background-color: $color-blue-light;
    border-radius: 1rem;

    @media #{$tablet} {
      padding: 4rem;
    }

    @media #{$normal} {
      margin-top: -12rem;
    }

    &__wrapper {
      z-index: 2;

      @media #{$mobile-only} {
        margin-bottom: 6rem;
      }
    }

    h3 {
      margin-bottom: 2rem;
    }

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      a {

        @include font--quarternary;

        font-size: 1.8rem;
        line-height: 1;
        color: $color-blue-dark;
        text-decoration: none;
      }
    }

    .icon-before {
      &:before {
        transform: rotate(90deg);
        margin-right: 1rem;
        font-size: 3rem !important;
      }
    }
  }

  &--showAnchorLinks {
    .LandingPageHeroWithImage__body {
      @media #{$mobile-only} {
        margin-bottom: 4rem;
      }
    }
  }

  .col-12 {
    @media #{$tablet-only} {
      padding: 0 1rem !important;
    }
  }
}
