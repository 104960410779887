@import 'assets/variables';
@import 'assets/mixins';

.Download {
  padding: 2rem;
  background-color: $color-blue-light;
  border-radius: 1rem;
  transition: background-color .1s linear;


  &__title {
    @include font--quarternary;

    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2rem;

    &:before {
      flex-shrink: 0;
      margin-right: 2rem;
      font-size: 3rem !important;
    }
  }


  &__wrapper {
    color: $color-blue-darkest;
    text-decoration: none;

    &:last-of-type {
      .Download {
        margin-bottom: 0;
      }
    }

    &:hover {
      color: $color-blue-darkest;
      text-decoration: none;

      .Download {
        background-color: $color-blue-primary;
      }
    }

    &:focus {
      .Download {
        background-color: $color-blue-dark;

        &__title {
          color: $color-white;
          text-decoration: none;

          &:before {
            background-position-y: -1em;
          }
        }
      }
    }

    .Download {
      margin-bottom: 1rem;
    }
  }
}
