@import 'assets/variables';
@import 'assets/mixins';

.NodeProductTeaser {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  &__content {
    order: 2;
  }

  &__image_wrapper {
    order: 1;
    height: 0;
    margin-bottom: 1.5rem;
    padding-bottom: 75%;
    background-color: $color-blue-light;
    border-radius: 1rem;
  }

  &__title {
    margin-bottom: 1rem;
    color: $color-blue-darkest;
    text-decoration: none;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      inset: 0 0 0 0;
    }

    h2 {
      @include h4;
    }
  }

  &__properties {
    & > div {
      &:not(:last-child) {
        margin-bottom: .5rem;
      }
    }

    .icon {
      margin-right: 1rem;
      font-size: 2.4rem;
    }
  }

  &__image {
    order: 1;
    width: 100%;
    border-radius: 1rem;

    @media #{$mobile-only} {
      margin-bottom: 6rem;
    }
  }
}
