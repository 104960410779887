.Page {
  .has-loading-items {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.2s ease-out;

    &.loading {
      transform: translateY(50px);
      opacity: 0;
      transition: all 0.2s ease-out;
    }
  }
}
