@import 'assets/variables';
@import 'assets/mixins';


.ViewRelatedHelpPages {
  position: relative;

  &__text {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 4rem;
    }

    h2 {
      margin-bottom: 0;
    }
  }

  &__button {
    &--desktop {
      display: none;

      @media #{$normal} {
        display: block;
      }
    }

    &--mobile {
      display: block;
      margin-top: 3rem;

      @media #{$normal} {
        display: none;
      }
    }
  }

  .slick-slide {
    margin: 0 2rem;

    @media #{$tablet} {
      margin: 0 1rem;
    }

    @media #{$normal} {
      margin: 0 2rem;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .slick-slider {
    position: initial;
  }

  .SliderRelated__next,
  .SliderRelated__prev {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    font-size: 3rem !important;

    @media #{$normal} {
      display: none;
    }

    &.disabled {
      .icon-before {
        opacity: .3;
        transition: opacity .1s linear;
      }
    }

    .icon-before {
      transition: opacity .1s linear;
    }
  }

  .SliderRelated__next {
    right: 0;
  }

  .SliderRelated__prev {
    right: 4rem;
    left: inherit ;

    .icon-before {
      position: relative;
      top: -2px;
    }
  }

  .Button {
    padding: 0;
  }
}
