@import 'assets/variables';
@import 'assets/mixins';

.ParagraphVideo {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  iframe {
    width: 100%;
    height: 19rem;

    @media #{$tablet} {
      height: 32.6rem;
    }

    @media #{$normal} {
      height: 42.8rem;
    }
  }

}
