@import 'assets/variables';

// -----------------------------------------------------------------------------
//
// Typography
//
// -----------------------------------------------------------------------------

@mixin font--primary($weight: normal, $style: normal) {
  font-family: $font--primary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--secondary($weight: normal, $style: normal) {
  font-family: $font--secondary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--tertiary($weight: normal, $style: normal) {
  font-family: $font--tertiary;
  font-weight: $weight;
  font-style: $style;
}

@mixin font--quarternary($weight: normal, $style: normal) {
  font-family: $font--quaternary;
  font-weight: $weight;
  font-style: $style;
}

@mixin h1 {
  @include font--secondary;

  margin-bottom: 2rem;
  font-size: $font-size--h1;
  font-weight: bold;
  line-height: $line-height--h1;

  @media #{$tablet} {
    margin-bottom: 4rem;
  }

  @media #{$normal} {
    font-size: $desktop--font-size--h1;
    line-height: $desktop--line-height--h1;
  }
}

@mixin h2 {
  @include font--tertiary;

  // font-weight: bold;
  margin-bottom: 1.5rem;
  font-size: $font-size--h2;
  line-height: $line-height--h2;

  @media #{$tablet} {
    margin-bottom: 2rem;
  }

  @media #{$normal} {
    @include font--secondary;

    font-size: $desktop--font-size--h2;
    line-height: $desktop--line-height--h2;
  }
}

@mixin h3 {
  @include font--quarternary;

  // font-weight: bold;
  margin-bottom: 1.5rem;
  font-size: $font-size--h3;
  line-height: $line-height--h3;

  @media #{$normal} {
    font-size: $desktop--font-size--h3;
    line-height: $desktop--line-height--h3;
  }
}

@mixin h4 {
  @include font--quarternary;

  // font-weight: bold;
  margin-bottom: 1rem;
  font-size: $font-size--h4;
  line-height: $line-height--h4;

  @media #{$normal} {
    font-size: $desktop--font-size--h4;
    line-height: $desktop--line-height--h4;
  }
}

@mixin intro {
  @include font--primary;

  // font-weight: bold;
  margin-bottom: 2rem;
  font-size: $font-size--body;
  line-height: $line-height--body;

  @media #{$tablet} {
    margin-bottom: 3rem;
  }

  @media #{$normal} {
    font-size: $desktop--font-size--intro;
    line-height: $desktop--line-height--intro;
  }
}

// Body mixins

@mixin body--large {
  @include font--primary;

  margin-bottom: 2rem;
  font-size: $font-size--body--large;
  line-height: $line-height--body--large;

  @media #{$normal} {
    margin-bottom: 3rem;
    font-size: $desktop--font-size--body--large;
    line-height: $desktop--line-height--body--large;
  }
}

@mixin body--regular {
  @include font--primary;

  margin-bottom: 2rem;
  font-size: $font-size--body;
  line-height: $line-height--body;
}

@mixin body--small {
  @include font--primary;

  margin-bottom: 2rem;
  font-size: $font-size--body--small;
  line-height: $line-height--body--small;
}

// Link mixins

@mixin link--regular {
  @include font--primary;

  font-size: $font-size--link;
  line-height: $line-height--link;
}

@mixin link--big {
  @include font--primary;

  font-size: $font-size--link--big;
  line-height: $line-height--link--big;
}

@mixin link-underline-animation {
  content: '';
  position: absolute;
  bottom: -.2rem;
  left: 0;
  transform-origin: right top;
  transform: scaleX(0);
  width: 100%;
  height: 1px;
  opacity: 1;
  background-color: $color-blue-darkest;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@mixin link-underline-animation-hover {
  transform-origin: left top;
  transform: scaleX(1);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@mixin link-box-container() {
  content: '';
  position: absolute;
  inset: 0;
}

@mixin link-box($psuedo-element: 'after') {
  position: relative;

  @if $psuedo-element == 'after' {
    button:after {
      @include link-box-container;
    }
    /* stylelint-disable-next-line at-rule-empty-line-before */
  } @else {
    button:before {
      @include link-box-container;
    }
  }
}

@mixin button-primary-variant {
  box-sizing: border-box;
  padding: 1.5rem 2.5rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: $color-blue-darkest;
  text-decoration: none;
  background-color: $color-blue-primary;
  border-radius: 50px;
  transition:
    background-color 0.33s cubic-bezier(0.1, 0, 0, 1),
    border-color 0.33s cubic-bezier(0.1, 0, 0, 1),
    color 0.33s cubic-bezier(0.1, 0, 0, 1);

  &:hover {
    color: $color-blue-darkest;
    text-decoration: none;
    background-color: $color-blue-light;
    border-color: $color-blue-light;
  }

  &:active,
  &:focus-visible {
    color: $color-white;
    text-decoration: none;
    background-color: $color-blue-darkest;

    .icon {
      background-position-y: -2.5rem;
    }

    .Button__circle {
      background-color: $color-blue-darkest;
    }
  }
}

// -----------------------------------------------------------------------------
//
// Icons
//
// -----------------------------------------------------------------------------

// The name of an icon
@mixin icon-name($name) {
  $offset-x: -#{index($icon-names, $name) - 1}em;

  background-position-x: $offset-x;
}

// The variant of an icon
@mixin icon-variant($variant) {
  $offset-y: calc(-#{$variant}em + 1em);

  background-position-y: $offset-y;
}
