@use 'sass:math';
@use "sass:map";

@import 'assets/variables';
@import 'bootstrap/scss/mixins';

// -----------------------------------------------------------------------------
//
// Grid utilities
//
// -----------------------------------------------------------------------------
// Override bootstrap grid mixins to allow different gutters on each breakpoint.
// -----------------------------------------------------------------------------

@mixin make-container($gutters: $grid-gutter-widths) {
  position: relative;
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map.get($gutters, $breakpoint);

      padding-right: math.div($gutter, 2);
      padding-left: math.div($gutter, 2);
    }
  }
}

@mixin make-gutters($gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map.get($gutters, $breakpoint);

      padding-right: math.div($gutter, 2);
      padding-left: math.div($gutter, 2);
    }
  }
}

@mixin make-row($sides: left-right, $gutters: $grid-gutter-widths) {
  display: flex;
  flex-wrap: wrap;

  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map.get($gutters, $breakpoint);

      @if $sides == all {
        margin: math.div($gutter, -2);
      } @else if $sides == left-right{
        margin-left: math.div($gutter, -2);
        margin-right: math.div($gutter, -2);
      } @else if $sides == top-bottom {
        margin-top: math.div($gutter, -2);
        margin-bottom: math.div($gutter, -2);
      }
    }
  }
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty

    @each $breakpoint in map-keys($grid-gutter-widths) {
      @include media-breakpoint-up($breakpoint) {
        $gutter: map.get($grid-gutter-widths, $breakpoint);

        padding-right: math.div($gutter, 2);
        padding-left: math.div($gutter, 2);
      }
    }
  }


  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }
  }
}
