@use "sass:list";

@import 'assets/variables';
@import 'assets/mixins';

.LanguageSwitchWrapper {
  display: flex;

  @media #{$mobile-down} {
    justify-content: center;
  }

  .LanguageSwitcher {
    width: 100%;

    ul {
      padding-left: 0;
    }

    li {
      list-style: none;

      &:before {
        content: "";
        overflow: hidden;
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 1rem;
        font-size: 2rem;
        text-indent: -9999px;
        vertical-align: middle;
        background-image: url('../../assets/images/icons/icongrid.svg?v=1.0');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: #{list.length($icon-names)}em #{$rows}em;
      }

      a {
        color: $color-blue-darkest;
        text-decoration: none;
      }
    }

    .language-en:before  {
      background-position-x: -14em;
    }

    .language-nl:before {
      background-position-x: -15em;

    }
  }
}
