@import 'assets/variables';

.CompaniesResult {
  padding: 2rem 0;
  vertical-align: middle;
  border-bottom: 1px solid #EFEFEF;

  &__regulation {
    text-align: right;
  }

  .label {
    display: block;
    font-weight: bold;

    @media #{$normal} {
      display: none;
    }
  }

}
