@import 'assets/variables';
@import 'assets/mixins';

.ViewArticleOverview {
  margin-bottom: 6rem;

  @media #{$normal} {
    margin-bottom: 8rem;
  }

  @media #{$normal} {
    margin-bottom: 12rem;
  }

  &__item {
    margin-bottom: 4rem;
  }

  .View__filters {
    @media #{$wide} {
      margin-right: -2rem;
      margin-left: -2rem;
    }

    .ViewFilters__filter {
      width: 100%;

      @media #{$normal} {
        width: 33%;
      }

      @media #{$wide} {
        margin: 0 2rem;
      }

      .FormElement__inner:first-child {
        z-index: 25;
      }
    }
  }
}
