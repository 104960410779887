@import 'assets/variables';
@import 'assets/mixins';

.SearchForm {
  @media #{$normal} {
    flex-direction: column;
  }

  form {
    display: flex;
    flex-direction: row;
    gap: 4rem;

    > div {
      flex-grow: 1;
    }

    .FormElement {
      margin-bottom: 4rem;
    }
  }

  b {
    @include font--secondary;
  }
}

.button-holder a {
  position: absolute;
  bottom: 0;
}
