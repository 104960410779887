@import "//hello.myfonts.net/count/418f5b";

@font-face {
  font-family: Lufga-Regular;
  src: url('../../assets/fonts/LufgaRegular/font.woff2') format('woff2'), url('../../assets/fonts/LufgaRegular/font.woff') format('woff');
}

@font-face {
  font-family: Lufga-Medium;
  src: url('../../assets/fonts/LufgaMedium/font.woff2') format('woff2'), url('../../assets/fonts/LufgaMedium/font.woff') format('woff');
}

@font-face {
  font-family: Lufga-SemiBold;
  src: url('../../assets/fonts/LufgaSemiBold/font.woff2') format('woff2'), url('../../assets/fonts/LufgaSemiBold/font.woff') format('woff');
}

@font-face {
  font-family: Lufga-Bold;
  src: url('../../assets/fonts/LufgaBold/font.woff2') format('woff2'), url('../../assets/fonts/LufgaBold/font.woff') format('woff');
}

// https://uncinc.monday.com/boards/1772269611/pulses/6971467610
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap';
