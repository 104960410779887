@import 'assets/variables';
@import 'assets/mixins';

.ParagraphDivider{
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  .Lottie {
    cursor: inherit;
  }
}
