@use "sass:list";

@import "assets/variables";
@import "assets/mixins";

.NewsletterSignup {
  .WebformBlock {
    &__title {
      label {
        display: none;
      }
    }
  }

  .WebformComponent__form {
    display: flex;
    justify-content: left;


    button {
      padding: 0 2rem;
      border-radius: 0 4rem 4rem 0;

      @media #{$mobile-only} {
        padding: 0 1.5rem;
        font-size: 0;

        &:before {
          content: '';
          overflow: hidden;
          display: inline-block;
          width: 1em;
          height: 1em;
          font-size: 2rem;
          text-indent: -9999px;
          vertical-align: middle;
          background-image: url("../../assets/images/icons/icongrid.svg?v=1.1");
          background-repeat: no-repeat;
          background-position-x: -10em;
          background-size: #{list.length($icon-names)}em #{$rows}em;
        }
      }

      &:hover {
        box-shadow: inset 0 0 1px 1px $color-blue-darkest;
      }

    }

    .WebformEmail {
      flex: 1 1 auto;

      label {
        display: none;
      }
    }

    .WebformEmail .TextField {
      margin-bottom: 0;

      input {
        border-right: 0;
        border-radius: 4rem 0 0 4rem;
      }

      .FormElement__error:after {
        top: 2rem;
      }
    }
  }
}
