@import 'assets/variables';
@import 'assets/mixins';

.NodeArticleDetail {
  margin-top: 6rem;

  &__hero {
    margin-bottom: 6rem;

    @media #{$tablet} {
      margin-bottom: 8rem;
    }

    @media #{$normal} {
      margin-bottom: 12rem;
    }
  }

  &__text {
    &__wrapper {
      @media #{$tablet} {
        order: 2;
      }
    }
  }

  &__image {
    width: 100%;
    height: 22.3rem;
    object-fit: cover;
    border-radius: 1rem;

    @media #{$tablet} {
      height: 18.8rem;
    }

    @media #{$normal} {
      height: 30.7rem;
    }

    &__wrapper {
      order: 2;

      @media #{$tablet} {
        order: 1;
      }
    }
  }

  &__title {
    margin-bottom: 2rem;

    @media #{$normal} {
      margin-bottom: 2.5rem;
    }
  }

  &__body {

    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3.2rem;

    @media #{$normal} {
      margin-bottom: 3rem;
    }
  }

  &__info {
    @media #{$tablet} {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__tags {
    display: flex;
    margin-right: 2rem;
    margin-bottom: 1rem;

    @media #{$mobile-only} {
      flex-wrap: wrap;
    }

    @media #{$tablet} {
      margin-bottom: 1rem;
    }

    &__tag {

      @include font--secondary;

      padding: 1rem 2rem;
      font-size: 1.6rem;
      line-height: 1.6rem;
      color: $color-blue-dark;
      text-decoration: none;
      white-space: nowrap;
      background-color: $color-green-light;
      border-radius: 5rem;

      transition:
        color .1s linear
        background-color .1s linear;

      @media #{$mobile-only} {
        margin-bottom: 1rem;
      }

      @media #{$mobile-only} {
        margin-right: 1rem;
        margin-left: 0;
      }


      &:hover {
        color: $color-blue-dark;
        text-decoration: none;
        background-color: $color-green-primary;
      }

      &:focus {
        color: $color-white;
        background-color: $color-green-dark;
      }

      & + & {
        margin-left: 1rem;

        @media #{$mobile-only} {
          margin-left: 0;
        }
      }
    }
  }

  &__date {
    display: flex;

    margin-bottom: 4rem;
    color: $color-blue-dark;

    @media #{$tablet} {
      margin-bottom: 1rem;
    }

    &__type {
      a {
        color: $color-blue-dark;

        &:hover {
          color: $color-blue-dark;
          text-decoration: none;
        }
      }
    }
  }
}
