@import 'assets/variables';
@import 'assets/mixins';

.NodePerson {

  display: flex;
  flex-direction: column;

  padding: 4rem 2rem;
  border: 3px solid $color-blue-light;
  border-radius: 1rem;

  @media #{$tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 4rem;

  }

  @media #{$normal} {
    flex-direction: column;
    justify-content: center;
  }

  &__body {
    @include body--small;

    color: $color-blue-dark;
  }

  &__intro {

    @media #{$tablet-only} {
      width: 50%;
    }
  }

  &__person {
    display: flex;
    align-items: center;

    &__image {

      margin-right: 2rem;

      @media #{$normal-only} {
        display: none;
      }

      img {
        width: 6rem;
        height: 6rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__info {
      &__title {

        margin-bottom: 1rem;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 2.8rem;
        color: $color-blue-dark;
      }

      &__phone {
        margin-bottom: 1rem;
      }

      &__phone,
      &__email {
        &:before {
          margin-right: 1.5rem;
          font-size: 2.4rem !important;
        }

        a {
          font-size: 1.6rem;
          color: $color-blue-dark;

          &:hover {
            color: $color-blue-dark;
            text-decoration: none;
          }
        }
      }
    }
  }

  h4 {
    color: $color-blue-darkest;
  }

}
