@import 'assets/variables';
@import 'assets/mixins';

.SearchTeaser {
  margin-top: 4rem;

  &__tag {
    width: fit-content;
    padding: .5rem 1.5rem;
    font-size: $font-size--body--small;
    font-weight: 700;
    color: $color-blue-darkest;
    text-transform: capitalize;
    background-color: $color-green-primary;
    border-radius: 3rem;

    label {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: $font-size--h4;
    line-height: $line-height--h4;
    color: $color-blue-darkest;
  }

  &__body,
  &__url a {
    font-family: $font--primary;
    font-size: $font-size--body--small;
    font-weight: 400;
    line-height: $line-height--h4;
    color: $color-blue-dark;
  }

}
