@import 'assets/variables';
@import 'assets/mixins';

.NodeArticleTeaser {
  margin-bottom: 4rem;

  &__image {
    width: 100%;
    margin-bottom: 1.5rem;
    object-fit: cover;
    border-radius: 1rem;

    &__wrapper {
      position: relative;
      overflow: hidden;
      order: 1;
      height: 0;
      margin-bottom: 1.5rem;
      padding-bottom: 66.667%;
      background-color: $color-blue-light;
      border-radius: 1rem;
    }
  }

  &__icon {
    position: absolute;
    z-index: 2;
    top: 2rem;
    left: 2rem;
    display: inline-block;
    padding: 1rem;
    background-color: rgba($color: $color-green-primary, $alpha: 50%);
    border-radius: 50%;

    .icon {
      font-size: 3rem !important;
    }
  }

  &__date__date {
    margin-bottom: 0.5rem;
  }

  a {
    transform: scale(1);
    color: inherit;
    text-decoration: none;
    transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;

      h4 {
        color: $color-blue-primary;
      }

      .NodeArticleTeaser__icon {
        background-color: rgba($color: $color-blue-primary, $alpha: 50%);
      }

      .NodeArticleTeaser__image__wrapper {
        img {
          transform: scale(1.1);
          transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }
      }
    }
  }

  h4 {
    margin-bottom: 0.5rem;
  }
}
