@import 'assets/variables';
@import 'assets/mixins';

.Hamburger {
  position: relative;
  width: 5rem;
  height: 5rem;

  background-color: $color-white;
  border-radius: 50%;
  transition: background-color .1s linear;

  &:hover {
    background-color: $color-blue-light;
  }

  &__lottie {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 3rem;

  }
}

.overflow-hidden {
  .Hamburger {
    background-color: transparent;
  }
}
