@import 'assets/variables';
@import 'assets/mixins';

.ParagraphText {
  margin-bottom: $paragraph-bottom-margin;

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-tablet;
  }

  @media #{$normal} {
    margin-bottom: $paragraph-bottom-margin-desktop;
  }

  &__inner {
    a {
      color: $color-blue-primary;
    }

    .Button {
      padding: 0;

      @media #{$mobile-only} {
        text-align: left;
        border-radius: 0;
      }
    }

  }

  &__read_more {
    position: relative;
    overflow: hidden;
    transition: height .33s linear;

    &:not(.ParagraphText__read_more--open):after {
      content: '';
      position: absolute;
      inset: 0 0 0 0;
      opacity: 1;
      background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #FFF 100%);
      transition: opacity .33s linear;
    }

    &__button {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 3rem;
      color: $color-blue-primary;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &--open {
      &:after {
        opacity: 0;
      }
    }

    .WysiwygField {
      p:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }


  .WysiwygField {
    p,
    ol,
    ul,
    li,
    h2,
    h3,
    h4 {
      color: $color-blue-dark;
    }


    p {
      margin-bottom: 3rem;
    }

    ol,
    ul {
      margin-bottom: 3rem;
      padding-inline-start: 2rem;

      li {
        &:not(:last-of-type) {
          margin-bottom: 0.5rem;
        }

        &:before {
          display: inline-block;
          width: 3.4rem;
          color: $color-blue-dark;
        }

      }
    }

    ol {
      counter-reset: item;
      padding-left: 0;
      list-style-type: none;

      li:before {
        content: counter(item) "  ";
        counter-increment: item;
        font-weight: 700;
      }
    }


    h3,
    h4,
    h5 {
      margin-top: 4rem;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
}
